import { defineKey } from '@ha/intl';

import { TeamTypes } from 'ha/pages/Careers/components/Teams/types';
import { ValueTypes } from 'ha/pages/Careers/components/Values/types';

export const VALUES: ValueTypes[] = [
  {
    title: defineKey('careers.values.enablers_title'),
    description: defineKey('careers.values.enablers_info'),
  },
  {
    title: defineKey('careers.values.changemakers_title'),
    description: defineKey('careers.values.changemakers_info'),
  },
  {
    title: defineKey('careers.values.connectors_title'),
    description: defineKey('careers.values.connectors_info'),
  },
  {
    title: defineKey('careers.values.ownership_title'),
    description: defineKey('careers.values.ownership_info'),
  },
];

export const TEAMS: TeamTypes[] = [
  {
    title: defineKey('careers.engineering_name'),
    description: defineKey('careers.engineering_intro'),
    urlBuilder: 'getCareersEngineering',
  },
  {
    title: defineKey('careers.product_ux_name'),
    description: defineKey('careers.product_ux_intro'),
    urlBuilder: 'getCareersProductDesign',
  },
  {
    title: defineKey('careers.data_analytics_name'),
    description: defineKey('careers.data_analytics_intro'),
    urlBuilder: 'getCareersDataAnalytics',
  },
  {
    title: defineKey('careers.marketing_name'),
    description: defineKey('careers.marketing_intro'),
    urlBuilder: 'getCareersMarketing',
  },
  {
    title: defineKey('careers.sales_name'),
    description: defineKey('careers.sales_intro'),
    urlBuilder: 'getCareersSales',
  },
  {
    title: defineKey('careers.cs_name'),
    description: defineKey('careers.cs_intro'),
    urlBuilder: 'getCareersSupport',
  },
  {
    title: defineKey('careers.finance_name'),
    description: defineKey('careers.finance_intro'),
    urlBuilder: 'getCareersFinance',
  },
  {
    title: defineKey('careers.hr_name'),
    description: defineKey('careers.hr_intro'),
    urlBuilder: 'getCareersPeopleOps',
  },
  {
    title: defineKey('careers.interns_name'),
    description: defineKey('careers.interns_intro'),
    urlBuilder: 'getInternshipsUrl',
  },
];
