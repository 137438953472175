import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { StepTypes } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    '& > p': {
      margin: 0,
      color: theme.palette.grey[800],
    },
  },
  title: {
    fontSize: units.pxToRem(20),
    lineHeight: 1.3,
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      maxWidth: units.pxToRem(170),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: units.pxToRem(154),
    },
  },
  description: {
    lineHeight: 1.38,
    paddingTop: units.pxToRem(8),
  },
}));

interface Props {
  step: StepTypes;
}

const Step: React.FC<Props> = ({ step }) => {
  const { classes } = useStyles();
  const { T } = useIntl();

  return (
    <div className={classes.container} data-test-locator="Careers/Step">
      <p className={classes.title}>{T(step.title)}</p>
      <p className={classes.description}>{T(step.description)}</p>
    </div>
  );
};

export { Step };
