import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { useConfig } from 'ha/helpers/config';

import { Imgix } from 'ha/modules/Image/Imgix';
import { Domain } from 'ha/pages/Careers/constants';
import { Props as ImgProps } from 'ha/ui/Img';

const useStyles = makeStyles()(() => ({
  img: {
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'left',
  },
}));

type Props = {
  name: string;
  domain: Domain;
} & Pick<ImgProps, 'className' | 'alt' | 'loading'>;

const Image: React.FC<Props> = ({ name, domain, className, alt, loading }) => {
  const { classes, cx } = useStyles();
  const { imgix } = useConfig();

  const imageURL = React.useMemo(
    () => `${imgix.sourceURL}/pages/careers/${domain}/${name}.jpg`,
    [domain, name, imgix.sourceURL],
  );

  return (
    <Imgix
      className={cx(classes.img, className)}
      htmlAttributes={{
        alt: alt || name,
        loading,
      }}
      src={imageURL}
      width={600}
    />
  );
};

export { Image };
