import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Grid, Link, units } from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';
import haLogo from 'ha/img-src/logos/housing-anywhere-logo.svg';

import { Img } from 'ha/ui/Img';

const useStyles = makeStyles()(theme => ({
  homepageLogo: {
    width: units.pxToRem(62),

    [theme.breakpoints.up('md')]: {
      width: units.pxToRem(82),
    },
  },
  careersHomepage: {
    fontStyle: 'italic',
    fontWeight: 600,
    fontSize: units.pxToRem(18),
    lineHeight: 1.11,
    textTransform: 'capitalize',
    color: theme.palette.grey[800],
    letterSpacing: '-0.01em',

    [theme.breakpoints.up('sm')]: {
      lineHeight: 1.33,
    },

    [theme.breakpoints.up('md')]: {
      fontSize: units.pxToRem(24),
    },
  },
}));

const HeaderLogo: React.FC = () => {
  const intl = useIntl();
  const { T, urlResolver } = intl;

  const { classes } = useStyles();
  return (
    <Link
      to={urlResolver.getCareersUrl()}
      data-test-locator="Careers/Header/Logo"
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Img
            isResponsive
            className={classes.homepageLogo}
            src={haLogo}
            alt="HousingAnywhere logo"
          />
        </Grid>
        <Grid item className={classes.careersHomepage}>
          {T('careers.nav.home')}
        </Grid>
      </Grid>
    </Link>
  );
};

export { HeaderLogo };
