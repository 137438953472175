import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { CustomButton } from '../CustomButton';
import { CustomContainer } from '../CustomContainer';
import { CustomTitle } from '../CustomTitle';

import { Step } from './Step';
import { StepTypes } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    backgroundColor: theme.palette.grey[50],
    margin: units.pxToRem(30, 0),
    padding: units.pxToRem(40, 0),
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& > div > p': {
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      margin: units.pxToRem(40, 0),
    },
  },
  subTitle: {
    fontSize: units.pxToRem(26),
    lineHeight: 1.22,
    color: theme.palette.grey[800],
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: units.pxToRem(36),
      margin: units.pxToRem(0, 80),
    },
    [theme.breakpoints.up('md')]: {
      margin: units.pxToRem(0),
    },
  },
  description: {
    fontSize: units.pxToRem(20),
    lineHeight: 1.5,
    margin: units.pxToRem(20, 0, 0),
    color: theme.palette.grey[800],
  },
  steps: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: units.pxToRem(24),
    marginTop: units.pxToRem(20),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      marginTop: units.pxToRem(32),
      padding: units.pxToRem(0, 4),
    },
    [theme.breakpoints.up('md')]: {
      gap: units.pxToRem(52),
      padding: units.pxToRem(0, 40),
    },
  },
  cta: {
    marginTop: units.pxToRem(24),
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: units.pxToRem(32),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: units.pxToRem(28),
    },
  },
}));

export interface Props {
  title: string;
  subTitle: string;
  description?: string;
  steps: StepTypes[];
  linkText: string;
  url: string;
  customClasses?: string;
}

const Hiring: React.FC<Props> = ({
  title,
  subTitle,
  description,
  steps,
  linkText,
  url,
  customClasses,
}) => {
  const { classes, cx } = useStyles();

  return (
    <section
      className={cx(classes.container, customClasses)}
      data-test-locator="Careers/Hiring"
    >
      <CustomContainer>
        <CustomTitle title={title} />
        <p className={classes.subTitle}>{subTitle}</p>
        {description && <p className={classes.description}>{description}</p>}

        <div className={classes.steps}>
          {steps.map(step => (
            <Step key={step.title} step={step} />
          ))}
        </div>

        <CustomButton className={classes.cta} href={url} variant="outlined">
          {linkText}
        </CustomButton>
      </CustomContainer>
    </section>
  );
};

export { Hiring };
