import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units, Link } from '@hbf/dsl/legacy';
import { ArrowDiagonal } from '@hbf/icons/streamline-bold';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { TeamTypes } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: units.pxToRem(12),
    padding: units.pxToRem(20),
    borderRadius: units.pxToRem(8),
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 6px rgba(60, 64, 67, 0.16)',
    transition: 'box-shadow 0.25s ease-out',
    '&:hover': {
      boxShadow: '0px 2px 16px rgba(60, 64, 67, 0.16)',
    },
    '& p': {
      margin: 0,
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: units.pxToRem(18),
    lineHeight: 1.33,
    color: theme.palette.grey[800],
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
  },
  arrowIcon: {
    fontSize: units.pxToRem(10),
    color: theme.palette.primary.main,
  },
  description: {
    lineHeight: 1.38,
    color: theme.palette.grey[600],
  },
}));

interface Props {
  team: TeamTypes;
}

const Team: React.FC<Props> = ({ team }) => {
  const { classes } = useStyles();
  const { T, urlResolver } = useIntl();

  return (
    <Link
      to={team.urlBuilder ? urlResolver[team.urlBuilder as string]() : '#'}
      className={classes.container}
      data-test-locator="Careers/Team"
    >
      <div className={classes.titleWrapper}>
        <p className={classes.title}>{T(team.title)}</p>
        <ArrowDiagonal className={classes.arrowIcon} />
      </div>
      <p className={classes.description}>{T(team.description)}</p>
    </Link>
  );
};

export { Team };
