import React from 'react';

import {
  SocialMediaFacebook,
  SocialMediaTwitter,
  SocialMediaYoutube,
  SocialMediaLinkedin,
} from '@hbf/icons/streamline-bold';

export const socialLinks = [
  {
    label: 'facebook',
    icon: <SocialMediaFacebook fontSize="inherit" />,
    urlBuilder: 'getFacebookUrl',
  },
  {
    label: 'twitter',
    icon: <SocialMediaTwitter fontSize="inherit" />,
    urlBuilder: 'getTwitterUrl',
  },
  {
    label: 'linkedin',
    icon: <SocialMediaLinkedin fontSize="inherit" />,
    urlBuilder: 'getLinkedinUrl',
  },
  {
    label: 'youtube',
    icon: <SocialMediaYoutube fontSize="inherit" />,
    urlBuilder: 'getYoutubeUrl',
  },
];

export enum Domain {
  HomePage = 'homepage',
  Culture = 'culture',
  HowWeHire = 'how-we-hire',
  DataAnalytics = 'data-analytics',
  Engineering = 'engineering',
  Finance = 'finance',
  Internships = 'internships',
  Marketing = 'marketing',
  PeopleOps = 'people-ops',
  ProductDesign = 'product-design',
  Sales = 'sales',
  Support = 'support',
}

export enum YoutubeVideoID {
  Internships = 'CxDd1A5Vbsk',
}
