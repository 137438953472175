import React from 'react';

import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { Link, units } from '@hbf/dsl/legacy';
import { ArrowRight } from '@hbf/icons/streamline-bold';

import { useIntl } from 'ha/i18n';
import { getIsAdvertiser } from 'ha/modules/AuthLogic/selectors';

const useStyles = makeStyles()(theme => ({
  container: {
    fontWeight: 600,
    fontSize: units.pxToRem(14),
    lineHeight: 1.29,
    textAlign: 'right',
    alignItems: 'center',
    color: theme.palette.primary.main,
    marginRight: units.pxToRem(10),
    textTransform: 'lowercase',

    '&:hover': {
      color: theme.palette.primary.dark,
    },

    [theme.breakpoints.up('lg')]: {
      marginRight: units.pxToRem(20),
    },
  },
  label: {
    marginRight: theme.spacing(0.8),
  },
  icon: {
    width: units.rem(0.8),
    height: units.rem(0.8),
    transform: 'rotate(-45deg)',
    marginBottom: theme.spacing(-0.25),
  },
}));

const HomepageLink: React.FC = () => {
  const { classes } = useStyles();
  const { T, urlResolver } = useIntl();

  const isAdvertiser = useSelector(getIsAdvertiser);

  return (
    <Link
      to={urlResolver.getHomeUrl({ explicitNoRedirect: isAdvertiser })}
      className={classes.container}
      data-test-locator="Careers/Header/Homepage"
    >
      <span className={classes.label}>
        {T('about.products.marketplace_title')}
      </span>
      <ArrowRight className={classes.icon} />
    </Link>
  );
};

export { HomepageLink };
