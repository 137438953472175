import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units, Box } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { Frame } from 'ha/components.legacy/VideoPlayer/components/Frame';

import { CustomButton } from './CustomButton';
import { CustomContainer } from './CustomContainer';

const useStyles = makeStyles<{ isVideoURL: boolean }>()(
  (theme, { isVideoURL }) => ({
    container: {
      position: 'relative',
      height: isVideoURL ? units.pxToRem(856) : 'unset',
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        height: 'unset',
      },
    },
    heroContainer: {
      backgroundColor: theme.palette.primary.accent,
      paddingTop: units.pxToRem(16),
      paddingBottom: units.pxToRem(isVideoURL ? 56 : 16),
      height: isVideoURL ? units.pxToRem(570) : 'unset',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        height: 'unset',
        marginBottom: 'unset',
      },
      [theme.breakpoints.up('md')]: {
        padding: units.pxToRem(40, 0),
        marginBottom: units.pxToRem(40),
      },
    },
    title: {
      fontFamily: sharpSansBold.fontFamily,
      fontSize: units.pxToRem(32),
      lineHeight: 1.25,
      color: theme.palette.grey[800],
      fontWeight: 600,
      margin: 0,
      [theme.breakpoints.up('sm')]: {
        fontSize: units.pxToRem(36),
        lineHeight: 1.22,
      },
    },
    description: {
      fontSize: units.pxToRem(20),
      lineHeight: 1.3,
      maxWidth: units.pxToRem(400),
      color: theme.palette.grey[800],
      margin: theme.spacing(1, 'auto', 3),
    },
    iframeContainer: {
      maxWidth: units.pxToRem(1016),
      margin: theme.spacing(3, 'auto'),
      height: units.pxToRem(570),
      position: 'absolute',
      top: '28%',
      left: 0,
      right: 0,
      [theme.breakpoints.down('md')]: {
        margin: 'unset',
        height: 'unset',
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        aspectRatio: '16/9',
      },
      '& > iframe': {
        [theme.breakpoints.down('md')]: {
          position: 'absolute',
          top: '0',
          left: '0',
          bottom: '0',
          right: '0',
          width: '100%',
          height: '100%',
        },
      },
    },
  }),
);

interface Props {
  title: string;
  description: string;
  customClasses?: string;
  /**
   * Supported format: Youtube video URL.
   * @example
   * import { getYoutubeEmbedUrl } from 'ha/modules/ListingEdit/helpers'
   * const youtubeEmbedUrl = getYoutubeEmbedUrl({youtubeId: 'abcde'});
   */
  videoURL?: string;
}

const Hero: React.FC<Props> = ({
  title,
  description,
  customClasses,
  videoURL,
}) => {
  const { classes, cx } = useStyles({ isVideoURL: Boolean(videoURL) });
  const { T, urlResolver } = useIntl();

  return (
    <Box className={classes.container}>
      <section
        className={classes.heroContainer}
        data-test-locator="Careers/Subpage/Hero"
      >
        <CustomContainer>
          <h1 className={classes.title}>{title}</h1>
          <p className={cx(classes.description, customClasses)}>
            {description}
          </p>
          <CustomButton
            variant="contained"
            href={urlResolver.getGreenHouseCareersUrl()}
          >
            {T('cta.view_open_roles')}
          </CustomButton>
        </CustomContainer>
      </section>
      {videoURL && (
        <Box
          className={classes.iframeContainer}
          data-test-locator="Careers/Subpage/Hero/Video"
        >
          <Frame
            loading="lazy"
            title={title}
            embedUrl={videoURL}
            allowFullScreen
          />
        </Box>
      )}
    </Box>
  );
};

export { Hero };
