import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { WorkTypes } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    '& > p': {
      margin: 0,
      color: theme.palette.grey[800],
    },
  },
  title: {
    fontSize: units.pxToRem(20),
    lineHeight: 1.3,
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
  },
  description: {
    lineHeight: 1.38,
    paddingTop: units.pxToRem(8),
  },
}));

const Work: React.FC<WorkTypes> = ({ title, description }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container} data-test-locator="Careers/Work">
      <p className={classes.title}>{title}</p>
      <p className={classes.description}>{description}</p>
    </div>
  );
};

export { Work };
