import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Button, Popover } from '@hbf/dsl/legacy';
import { ArrowButtonDown, ArrowButtonUp } from '@hbf/icons/streamline-bold';

import { Link } from './constants';
import { DropdownMenuItem } from './DropdownMenuItem';

const useStyles = makeStyles()(theme => ({
  menu: {
    padding: theme.spacing(2, 3),
    color: theme.palette.grey[500],
  },
  link: {
    padding: theme.spacing(1, 0),
  },
  openedLink: {
    color: theme.palette.grey[800],
  },
}));

interface Props {
  id: string;
  title: string;
  data: Link[];
  testId: string;
}

const DropdownMenu: React.FC<Props> = ({ id, title, data, testId }) => {
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const setAnchorElement = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(event => setAnchorEl(event.currentTarget), []);

  const removeAnchorElement = React.useCallback(() => setAnchorEl(null), []);

  const isOpen = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Button
        color="inherit"
        aria-owns={isOpen ? id : undefined}
        aria-haspopup="true"
        data-test-locator={`${testId}/Btn`}
        onClick={setAnchorElement}
        iconSize="small"
        endIcon={isOpen ? <ArrowButtonUp /> : <ArrowButtonDown />}
        className={isOpen ? classes.openedLink : ''}
      >
        {title}
      </Button>
      <Popover
        id={id}
        open={isOpen}
        keepMounted
        disablePortal
        onClose={removeAnchorElement}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        PaperProps={{ elevation: 10 }}
      >
        <div className={classes.menu} data-test-locator={`${testId}/Menu`}>
          {data.map(link => (
            <div className={classes.link} key={link.title}>
              <DropdownMenuItem {...link} />
            </div>
          ))}
        </div>
      </Popover>
    </React.Fragment>
  );
};

export { DropdownMenu };
