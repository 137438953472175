import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { STATISTICS } from './constants';

const useStyles = makeStyles()(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(1.5, 3),
    padding: theme.spacing(0, 0, 3),
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 0, 2),
    },
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(1.5, 2.5),
      padding: theme.spacing(0, 12.5, 3, 0),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 16, 3, 0),
    },
  },
  number: {
    fontSize: units.rem(2.625),
    lineHeight: units.rem(3.125),
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('md')]: {
      fontSize: units.rem(3.25),
      lineHeight: units.rem(3.5),
    },
  },
  title: {
    fontSize: units.rem(0.75),
    letterSpacing: theme.spacing(0.25),
    fontWeight: 600,
    color: theme.palette.grey[800],
    textTransform: 'uppercase',
  },
}));

export const Statistics: React.FC = () => {
  const { classes } = useStyles();
  const { T } = useIntl();

  return (
    <div className={classes.list}>
      {STATISTICS.map(({ number, title }) => (
        <div key={title} data-test-locator="Careers/Statistic">
          <p className={classes.number}>{T(number)}</p>
          <p className={classes.title}>{T(title)}</p>
        </div>
      ))}
    </div>
  );
};
