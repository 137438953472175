import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';

import { splitOnTwo } from 'ha/pages/Careers/helpers';

import { PerkTypes } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      '&:first-of-type': {
        gridColumnStart: 'span 2',
      },
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: units.rem(4.5),
    height: units.rem(4.5),
    backgroundColor: theme.palette.mono.light,
    borderRadius: '50%',
  },
  icon: {
    fontSize: units.rem(2),
    color: theme.palette.secondary.main,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.grey[800],
    margin: 0,
  },
}));

interface Props {
  perk: PerkTypes;
}

const Perk: React.FC<Props> = ({ perk }) => {
  const { classes } = useStyles();
  const { T } = useIntl();
  const Icon = perk.icon();

  const title = React.useMemo(
    () =>
      splitOnTwo(T(perk.title)).map(text => (
        <p className={classes.title} key={text}>
          {text}
        </p>
      )),
    [T, classes.title, perk],
  );

  return (
    <section className={classes.container} data-test-locator="Careers/Perk">
      <div className={classes.iconWrapper}>
        <Icon className={classes.icon} />
      </div>
      <div>{title}</div>
    </section>
  );
};

export { Perk };
