import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Container, Grid, units } from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';

import { Accordion } from './Accordion';
import { QuestionsTypes } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      maxWidth: units.pxToRem(646),
      padding: 0,
    },
  },
  item: {
    marginBottom: units.pxToRem(16),

    [theme.breakpoints.up('sm')]: {
      marginBottom: units.pxToRem(20),
    },

    '&:last-of-type': {
      marginBottom: units.pxToRem(20),
    },
  },
}));

interface Props {
  questions: QuestionsTypes[];
}

const Accordions: React.FC<Props> = ({ questions }) => {
  const { classes } = useStyles();
  const { T } = useIntl();
  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleClick = React.useCallback(
    (index: number) => {
      setActiveIndex(index !== activeIndex ? index : -1);
    },
    [activeIndex],
  );

  return (
    <Container
      className={classes.container}
      data-test-locator="HowWeHire/FAQ/Accordions"
    >
      <Grid container direction="column" wrap="nowrap">
        {questions.map(({ summary, details }, idx) => (
          <Grid item key={summary} className={classes.item}>
            <Accordion
              isOpen={activeIndex === idx}
              onClick={() => {
                handleClick(idx);
              }}
              summary={T(summary)}
              details={T(details)}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export { Accordions };
