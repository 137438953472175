import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Container, units } from '@hbf/dsl/legacy';

const useStyles = makeStyles()(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      maxWidth: units.rem(66.5),
    },
  },
}));

interface Props {
  children: React.ReactNode;
}

export const CustomContainer: React.FC<Props> = ({ children }) => {
  const { classes } = useStyles();

  return <Container className={classes.container}>{children}</Container>;
};
