import React, { ElementType, HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLElement> {
  children: string;
  renderWith?: ElementType;
}

const RenderDangerously = <T,>({
  renderWith: Tag = 'div',
  children,
  ...rest
}: Props & T) => (
  <Tag
    dangerouslySetInnerHTML={{
      __html: children,
    }}
    {...rest}
  />
);

export { RenderDangerously };
