import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { Domain } from 'ha/pages/Careers/constants';

import { Card } from '../Card';
import { CardTypes } from '../Card/types';
import { CustomTitle } from '../CustomTitle';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: units.pxToRem(30, 0),
    '& > p': {
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(40, 0),
    },
  },
  subTitle: {
    fontSize: units.pxToRem(28),
    lineHeight: 1.22,
    color: theme.palette.grey[800],
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    margin: '0 auto',
    maxWidth: units.pxToRem(290),
    [theme.breakpoints.up('sm')]: {
      fontSize: units.pxToRem(36),
      maxWidth: units.pxToRem(400),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'unset',
    },
  },
  description: {
    fontSize: units.pxToRem(20),
    lineHeight: 1.5,
    margin: units.pxToRem(20, 0, 0),
    color: theme.palette.grey[800],
    [theme.breakpoints.up('sm')]: {
      margin: units.pxToRem(20, 62, 0),
    },
    [theme.breakpoints.up('md')]: {
      margin: units.pxToRem(20, 208, 0),
    },
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    gap: units.pxToRem(22),
    marginTop: units.pxToRem(20),
    padding: units.pxToRem(0, 8),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginTop: units.pxToRem(32),
      gap: units.pxToRem(12),
      padding: units.pxToRem(0),
    },
    [theme.breakpoints.up('md')]: {
      gap: units.pxToRem(16),
      padding: units.pxToRem(0, 28),
    },
  },
}));

interface Props {
  title: string;
  subTitle: string;
  description: string;
  cards: CardTypes[];
  domain: Domain;
}

const Growth: React.FC<Props> = ({
  title,
  subTitle,
  description,
  cards,
  domain,
}) => {
  const { classes } = useStyles();

  return (
    <section className={classes.container}>
      <CustomTitle title={title} />
      <p className={classes.subTitle}>{subTitle}</p>
      <p className={classes.description}>{description}</p>

      <div className={classes.cards}>
        {cards.map(card => (
          <Card key={card.title} card={card} domain={domain} />
        ))}
      </div>
    </section>
  );
};

export { Growth };
