import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Container, Typography, units, Link } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { CustomButton } from '../CustomButton';
import { CustomTitle } from '../CustomTitle';

import { SocialLinks } from './SocialLinks';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    backgroundColor: theme.palette.grey[50],
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: units.pxToRem(40, 16),
    rowGap: units.pxToRem(40),

    [theme.breakpoints.up('sm')]: {
      padding: units.pxToRem(40, 24),
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: units.pxToRem(60, 32),
      maxWidth: units.rem(63.5),
    },
  },
  headContainer: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: units.pxToRem(460),
    },
  },
  head: {
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    fontSize: units.rem(2),
    lineHeight: 1.25,
    color: theme.palette.grey[800],

    [theme.breakpoints.up('sm')]: {
      fontSize: units.rem(2.25),
      lineHeight: 1.22,
    },
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  link: {
    display: 'block',
    textDecoration: 'underline',
    color: theme.palette.primary.main,

    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  socialLinksContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  socialLinksLabel: {
    fontWeight: 600,
    fontSize: units.rem(1),
    lineHeight: 1.25,
    color: theme.palette.grey[800],
    marginRight: units.pxToRem(12),

    [theme.breakpoints.up('md')]: {
      lineHeight: 1.38,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: units.pxToRem(28),
    justifyContent: 'flex-end',

    '&:first-of-type': {
      rowGap: units.pxToRem(30),
    },
  },
}));

export interface Props {
  title?: string;
  className?: string;
}

const Footer: React.FC<Props> = ({ title, className }) => {
  const { classes, cx } = useStyles();
  const { T, urlResolver } = useIntl();

  return (
    <footer
      className={cx(classes.wrapper, className)}
      data-test-locator="Careers/Footer"
    >
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.column}>
          <div className={classes.headContainer}>
            <CustomTitle title={T('careers.sub_head.join')} />
            <Typography.Paragraph className={classes.head}>
              {title ?? T('careers.open_roles.title')}
            </Typography.Paragraph>
          </div>

          <div className={classes.buttonsWrapper}>
            <div className={classes.buttonGroup}>
              <CustomButton
                variant="contained"
                href={urlResolver.getGreenHouseCareersUrl()}
                rel="no-follow"
                data-test-locator="Careers/Header/GreenHouse"
              >
                {T('cta.view_open_roles')}
              </CustomButton>
            </div>
          </div>
        </div>

        <div className={classes.column}>
          <Link
            to={urlResolver.getCookiePolicyUrl()}
            rel="nofollow noopener noreferrer"
          >
            <Typography.Paragraph className={classes.link}>
              {T('cookie_policy_header')}
            </Typography.Paragraph>
          </Link>
          <div className={classes.socialLinksContainer}>
            <Typography.Paragraph className={classes.socialLinksLabel}>
              {T('Follow us')}
            </Typography.Paragraph>
            <SocialLinks />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export { Footer };
