import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { CustomTitle } from '../CustomTitle';

import { ValueTypes } from './types';
import { Value } from './Value';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: units.pxToRem(30, 0),
    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(40, 0),
    },
    '& > p': {
      textAlign: 'center',
    },
  },
  description: {
    fontSize: units.pxToRem(20),
    lineHeight: 1.5,
    color: theme.palette.grey[800],
    margin: `${theme.typography.pxToRem(8)} auto 0`,
    maxWidth: units.pxToRem(610),
  },
  values: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: units.pxToRem(24),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  fullList: {
    padding: units.pxToRem(0, 8),
    marginTop: units.pxToRem(32),
    [theme.breakpoints.up('sm')]: {
      padding: units.pxToRem(0, 38),
      gap: units.pxToRem(32, 36),
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('md')]: {
      gap: units.pxToRem(52),
      padding: 0,
    },
  },
  shortList: {
    padding: 0,
    marginTop: units.pxToRem(20),
    [theme.breakpoints.up('sm')]: {
      padding: units.pxToRem(0, 100),
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
      marginTop: units.pxToRem(32),
    },
  },
}));

export interface Props {
  title: string;
  description: string;
  values: ValueTypes[];
  customClasses?: string;
  shortList?: boolean;
}

const Values: React.FC<Props> = ({
  title,
  description,
  values,
  customClasses,
  shortList,
}) => {
  const { classes, cx } = useStyles();

  return (
    <section className={cx(classes.container, customClasses)}>
      <CustomTitle title={title} />
      <p className={classes.description}>{description}</p>

      <div
        className={cx(
          classes.values,
          shortList ? classes.shortList : classes.fullList,
        )}
      >
        {values.map((value, index) => (
          <Value key={value.title} index={index} value={value} />
        ))}
      </div>
    </section>
  );
};

export { Values };
