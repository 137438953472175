import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { Image, Link } from 'ha/pages/Careers/components';
import { Domain } from 'ha/pages/Careers/constants';

import { Statistics } from './Statistics';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3.75, 0),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 0),
    },
  },
  img: {
    height: units.rem(14.75),
    borderRadius: units.rem(1),
    [theme.breakpoints.up('sm')]: {
      width: units.rem(20.375),
      height: units.rem(25),
    },
    [theme.breakpoints.up('md')]: {
      width: units.rem(31.75),
      height: units.rem(28),
    },
  },
  content: {
    flex: 1,
    textAlign: 'center',
    padding: theme.spacing(2, 0, 0),
    [theme.breakpoints.up('sm')]: {
      textAlign: 'start',
      padding: theme.spacing(0, 0, 0, 2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 4, 0),
    },
  },
  title: {
    fontSize: units.rem(1.75),
    lineHeight: units.rem(2.125),
    color: theme.palette.grey[800],
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: units.rem(1.875),
      lineHeight: units.rem(2.5),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: units.rem(2.25),
      lineHeight: units.rem(2.75),
    },
  },
  description: {
    lineHeight: units.rem(1.5),
    margin: theme.spacing(1, 0),
    color: theme.palette.grey[800],
    [theme.breakpoints.up('sm')]: {
      fontSize: units.rem(1.125),
      margin: theme.spacing(1.5, 0, 1),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: units.rem(1.25),
      lineHeight: units.rem(1.875),
      margin: theme.spacing(1.5, 0, 2.5),
    },
  },
}));

const International: React.FC = () => {
  const { classes } = useStyles();
  const { T, urlResolver } = useIntl();

  return (
    <section className={classes.container}>
      <Image
        alt={T('careers.international.title')}
        name="international2"
        domain={Domain.HomePage}
        className={classes.img}
        loading="lazy"
      />
      <div className={classes.content}>
        <h3 className={classes.title}>{T('careers.international.title')}</h3>
        <p className={classes.description}>
          {T('careers.international.description')}
        </p>
        <Statistics />
        <Link url={urlResolver.getAboutUrl()} linkText={T('about_ha.title')} />
      </div>
    </section>
  );
};

export { International };
