import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { Domain } from 'ha/pages/Careers/constants';

import { CustomTitle } from '../CustomTitle';

import { Story } from './Story';
import { StoryTypes } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    textAlign: 'center',
    padding: units.pxToRem(30, 0),
    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(40, 0),
    },
  },
  bottomPosition: {
    padding: units.pxToRem(30, 0, 60),
    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(40, 0, 80),
    },
  },
  subTitle: {
    fontFamily: sharpSansBold.fontFamily,
    fontSize: units.pxToRem(28),
    lineHeight: 1.22,
    color: theme.palette.grey[800],
    fontWeight: 600,
    margin: '0 auto',
    maxWidth: units.pxToRem(260),
    [theme.breakpoints.up('sm')]: {
      fontSize: units.pxToRem(36),
      maxWidth: units.pxToRem(330),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'unset',
    },
  },
  stories: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: units.pxToRem(12),
    marginTop: units.pxToRem(20),
    textAlign: 'start',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      marginTop: units.pxToRem(32),
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    },
  },
  isSingleStory: {
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  largeSpacing: {
    [theme.breakpoints.up('sm')]: {
      gap: units.pxToRem(16),
    },
    [theme.breakpoints.up('md')]: {
      gap: units.pxToRem(24),
    },
  },
}));

interface Props {
  title: string;
  subTitle: string;
  stories: StoryTypes[];
  domain: Domain;
  customClasses?: string;
  isBottom?: boolean;
  spacing?: 'large';
}

const Stories: React.FC<Props> = ({
  title,
  subTitle,
  stories,
  domain,
  customClasses,
  isBottom,
  spacing,
}) => {
  const { classes, cx } = useStyles();

  const isSingleStory = React.useMemo(
    () => stories.length === 1,
    [stories.length],
  );

  return (
    <section
      className={cx(classes.container, customClasses, {
        [classes.bottomPosition]: isBottom,
      })}
      data-test-locator="Careers/Stories"
    >
      <CustomTitle title={title} />
      <p className={classes.subTitle}>{subTitle}</p>

      <div
        className={cx(classes.stories, {
          [classes.largeSpacing]: spacing === 'large',
          [classes.isSingleStory]: isSingleStory,
        })}
        data-test-locator="Careers/Stories/Grid"
      >
        {stories.map(story => (
          <Story
            key={story.name}
            story={story}
            domain={domain}
            isSingleStory={isSingleStory}
          />
        ))}
      </div>
    </section>
  );
};

export { Stories };
