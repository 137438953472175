import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { CustomButton, Image } from 'ha/pages/Careers/components';
import { Domain } from 'ha/pages/Careers/constants';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column-reverse',
    rowGap: units.pxToRem(12),
    padding: units.pxToRem(16, 0, 30),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      rowGap: 0,
      padding: units.pxToRem(40, 0, 30),
    },
    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(40, 0),
    },
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'start',
      textAlign: 'start',
      rowGap: units.pxToRem(24),
      padding: units.pxToRem(40, 12, 0, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(48, 44, 0, 0),
    },
    [theme.breakpoints.up('lg')]: {
      padding: units.pxToRem(68, 88, 0, 0),
    },
  },
  title: {
    fontSize: units.pxToRem(32),
    lineHeight: 1.25,
    color: theme.palette.grey[800],
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: units.pxToRem(36),
      lineHeight: 1.22,
    },
  },
  cta: {
    display: 'block',
    margin: units.pxToRem(16, 0),
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
      margin: units.pxToRem(0, 12, 0, 0),
    },
  },
  img: {
    height: units.pxToRem(180),
    borderRadius: units.pxToRem(8),
    objectPosition: 'center',
    [theme.breakpoints.up('sm')]: {
      objectPosition: 'right',
      width: units.pxToRem(314),
      height: units.pxToRem(276),
      borderRadius: units.pxToRem(16),
    },
    [theme.breakpoints.up('md')]: {
      objectPosition: 'top',
      width: units.pxToRem(600),
      height: units.pxToRem(332),
    },
  },
}));

const Hero: React.FC = () => {
  const { classes } = useStyles();
  const { T, urlResolver } = useIntl();

  return (
    <section className={classes.container}>
      <div className={classes.content}>
        <h1 className={classes.title}>{T('careers.main.hero')}</h1>
        <div>
          <CustomButton
            variant="contained"
            href={urlResolver.getGreenHouseCareersUrl()}
            className={classes.cta}
          >
            {T('cta.view_open_roles')}
          </CustomButton>
          {/* <Link */}
          {/*  url={urlResolver.getInternshipsUrl()} */}
          {/*  linkText={T('cta.looking_for_internships')} */}
          {/* /> */}
        </div>
      </div>
      <Image
        alt={T('careers.main.hero')}
        className={classes.img}
        name="hero7"
        domain={Domain.HomePage}
      />
    </section>
  );
};

export { Hero };
