import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';

import { Domain } from 'ha/pages/Careers/constants';

import { Image } from '../Image';

import { StoryTypes } from './types';

const useStyles = makeStyles<void, 'quote' | 'img'>()(
  (theme, _params, classes) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.grey[50],
      borderRadius: units.pxToRem(16),
      padding: units.pxToRem(12),
      overflow: 'hidden',
    },
    withoutImage: {
      [theme.breakpoints.up('md')]: {
        padding: units.pxToRem(20),
      },
      [`& .${classes.quote}`]: {
        margin: units.pxToRem(0, 0, 12),
      },
    },
    isSingleStory: {
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row-reverse',
        columnGap: units.pxToRem(32),
        padding: units.pxToRem(32),
        '& > div': {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        },
        [`& .${classes.quote}`]: {
          margin: units.pxToRem(0, 0, 12),
        },
        [`& .${classes.img}`]: {
          flexGrow: 0.5,
          width: units.pxToRem(200),
          maxWidth: units.pxToRem(318),
        },
      },
      [theme.breakpoints.up('md')]: {
        '& > div': {
          justifyContent: 'center',
        },
        [`& .${classes.img}`]: {
          flexGrow: 0,
          width: units.pxToRem(476),
          maxWidth: 'unset',
        },
      },
    },
    quote: {
      lineHeight: 1.38,
      color: theme.palette.grey[800],
      margin: units.pxToRem(12, 0),
    },
    name: {
      fontSize: units.pxToRem(20),
      lineHeight: 1.5,
      color: theme.palette.grey[600],
      margin: units.pxToRem(0, 0, 4),
    },
    jobTitle: {
      color: theme.palette.grey[600],
      lineHeight: 1.25,
      margin: 0,
    },
    img: {
      height: units.pxToRem(223),
      objectPosition: 'center',
      borderRadius: units.pxToRem(8),
    },
  }),
);

interface Props {
  story: StoryTypes;
  domain: Domain;
  isSingleStory: boolean;
}

const Story: React.FC<Props> = ({ story, domain, isSingleStory }) => {
  const { classes, cx } = useStyles();
  const { T } = useIntl();

  return (
    <section
      className={cx(classes.container, {
        [classes.withoutImage]: !story.image,
        [classes.isSingleStory]: isSingleStory,
      })}
      data-test-locator="Careers/Story"
    >
      {story.image && (
        <Image
          alt={T(story.name)}
          name={story.image}
          domain={domain}
          className={classes.img}
          loading="lazy"
        />
      )}
      <div>
        <p className={classes.quote}>{T(story.quote)}</p>
        <p className={classes.name}>{T(story.name)}</p>
        <p className={classes.jobTitle}>{T(story.jobTitle)}</p>
      </div>
    </section>
  );
};

export { Story };
