import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { CAREERS } from 'ha/constants/pageNames';

import { useIntl } from 'ha/i18n';

import { Page, CustomContainer, Teams } from 'ha/pages/Careers/components';
import { Domain } from 'ha/pages/Careers/constants';

import { Values } from '../../../components/Values';
import { VALUES, TEAMS } from '../constants';

import { Culture } from './Culture';
import { Hero } from './Hero';
import { International } from './International';
import { Perks } from './Perks';
import { Quote } from './Quote';

const useStyles = makeStyles()(() => ({
  teams: {
    '& > div > div': {
      gap: units.pxToRem(16),
    },
  },
}));

const HomePage: React.FC = () => {
  const { T } = useIntl();
  const { classes } = useStyles();

  return (
    <Page
      name={CAREERS}
      meta={{
        title: T('careers.meta_title'),
        description: T('careers.meta_description'),
      }}
    >
      <CustomContainer>
        <Hero />
        <Values
          title={T('careers.sub_head.values')}
          description={T('careers.main.about')}
          values={VALUES}
        />
        <Quote
          imageName="djordy-ceo4"
          domain={Domain.HomePage}
          variant="outlined"
          quote={T('careers.quote.ceo_quote')}
          name={T('careers.quote.ceo_name')}
          jobTitle={T('careers.quote.ceo_role')}
        />
      </CustomContainer>
      <Teams
        title={T('careers.sub_head.teams')}
        subTitle={T('careers.teams.title')}
        description={T('careers.teams.intro')}
        customClasses={classes.teams}
        variant="contained"
        columnsNumber={3}
        teams={TEAMS}
      />
      <CustomContainer>
        <International />
        <Perks />
        <Culture />
        <Quote
          imageName="carlos"
          domain={Domain.HomePage}
          variant="contained"
          quote={T('careers.quote.main_1')}
          name={T('careers.quote.main_1_name')}
          jobTitle={T('careers.quote.main_quote_1_role')}
        />
      </CustomContainer>
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default HomePage;
