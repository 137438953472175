import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { Domain } from 'ha/pages/Careers/constants';

import { Card } from '../Card';
import { CardTypes } from '../Card/types';
import { CustomTitle } from '../CustomTitle';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: units.pxToRem(30, 0),
    '& > p': {
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(40, 0),
    },
  },
  subTitle: {
    fontSize: units.pxToRem(28),
    lineHeight: 1.22,
    color: theme.palette.grey[800],
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      fontSize: units.pxToRem(36),
    },
  },
  description: {
    fontSize: units.pxToRem(20),
    lineHeight: 1.5,
    margin: '0 auto',
    marginTop: units.pxToRem(20),
    color: theme.palette.grey[800],
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    gap: units.pxToRem(16),
    marginTop: units.pxToRem(20),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginTop: units.pxToRem(32),
      gap: units.pxToRem(12),
    },
    [theme.breakpoints.up('md')]: {
      gap: units.pxToRem(22),
    },
  },
}));

interface Props {
  title: string;
  subTitle: string;
  description: string;
  cards: CardTypes[];
  domain: Domain;
  customClasses?: string;
}

const Organize: React.FC<Props> = ({
  title,
  subTitle,
  description,
  cards,
  domain,
  customClasses,
}) => {
  const { classes, cx } = useStyles();

  return (
    <section className={cx(classes.container, customClasses)}>
      <CustomTitle title={title} />
      <p className={classes.subTitle}>{subTitle}</p>
      <p className={classes.description}>{description}</p>

      <div className={classes.cards}>
        {cards.map(card => (
          <Card key={card.title} card={card} domain={domain} />
        ))}
      </div>
    </section>
  );
};

export { Organize };
