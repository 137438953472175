import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { CustomTitle } from 'ha/pages/Careers/components';

import { PERKS } from './constants';
import { Perk } from './Perk';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: theme.spacing(3.75, 0),
    '& > p': {
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 19.5, 7.5),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5, 21.25, 7.5),
    },
  },
  subTitle: {
    color: theme.palette.grey[800],
    fontSize: units.rem(1.75),
    lineHeight: units.rem(2.125),
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: units.rem(2.125),
      lineHeight: units.rem(2.75),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: units.rem(2.25),
    },
  },
  description: {
    fontSize: units.rem(1.125),
    lineHeight: units.rem(1.5),
    color: theme.palette.grey[800],
    margin: theme.spacing(2.25, 0, 0),
    [theme.breakpoints.up('sm')]: {
      fontSize: units.rem(1.25),
      lineHeight: units.rem(1.875),
      margin: theme.spacing(2.25, 8, 0),
    },
  },
  perks: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    rowGap: theme.spacing(5),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      rowGap: theme.spacing(5.5),
      marginTop: theme.spacing(6.5),
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
}));

const Perks: React.FC = () => {
  const { classes } = useStyles();
  const { T } = useIntl();

  return (
    <section className={classes.container}>
      <CustomTitle title={T('careers.sub_head.perks')} />
      <p className={classes.subTitle}>{T('careers.perks.title')}</p>
      <p className={classes.description}>{T('careers.perks.intro')}</p>

      <div className={classes.perks}>
        {PERKS.map(perk => (
          <Perk key={perk.title} perk={perk} />
        ))}
      </div>
    </section>
  );
};

export { Perks };
