import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { normalizeIndex } from 'ha/pages/Careers/helpers';

import { ValueTypes } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    columnGap: units.pxToRem(8),
  },
  index: {
    width: units.pxToRem(40),
    height: units.pxToRem(40),
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: units.border(3, 'solid', theme.palette.primary.light),
    borderRadius: '50%',
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    color: theme.palette.grey[800],
  },
  title: {
    fontSize: units.pxToRem(20),
    lineHeight: 1.3,
    color: theme.palette.grey[800],
    fontFamily: sharpSansBold.fontFamily,
    margin: units.pxToRem(6, 0, 8),
  },
  description: {
    margin: 0,
    lineHeight: 1.38,
    color: theme.palette.grey[600],
  },
}));

interface Props {
  index: number;
  value: ValueTypes;
}

const Value: React.FC<Props> = ({ index, value }) => {
  const { classes } = useStyles();
  const { T } = useIntl();

  return (
    <div className={classes.container} data-test-locator="Careers/Value">
      <span className={classes.index}>{normalizeIndex(index)}</span>
      <div>
        <p className={classes.title}>{T(value.title)}</p>
        <p className={classes.description}>{T(value.description)}</p>
      </div>
    </div>
  );
};

export { Value };
