import { defineKey } from '@ha/intl';

import { ItemTypes } from './types';

export const STATISTICS: ItemTypes[] = [
  {
    title: defineKey('about.team.people_label'),
    number: defineKey('about.team.people_number'),
  },
  {
    title: defineKey('about.team.nationalities_label'),
    number: defineKey('about.team.nationalities_number'),
  },
  {
    title: defineKey('about.team.age_label'),
    number: defineKey('about.team.age_number'),
  },
  {
    title: defineKey('about.team.women_label'),
    number: defineKey('about.team.women_number'),
  },
];
