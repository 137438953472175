import React from 'react';

import { Helmet } from 'react-helmet-async';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';
import { CAREERS } from 'ha/constants/pageNames';

import { MetaTags } from 'ha/types/MetaTags';

import { Page as BasicPage } from 'ha/modules/Page/containers/Page';

import { Footer, Props as FooterProps } from './Footer/Footer';
import { Header } from './Header';

interface Props {
  name: string;
  meta: MetaTags;
  footerProps?: FooterProps;
}

const Page: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  meta: { title, description },
  children,
  footerProps = {},
}) => {
  return (
    <BasicPage
      name={name}
      category={CAREERS}
      hasHeader={false}
      showBottomNav={false}
      hasFooter={false}
      activeFonts={[sharpSansBold]}
    >
      <Helmet>
        <title key="title">{title}</title>
        <meta key="meta description" name="description" content={description} />
        <meta
          key="meta og:description"
          property="og:description"
          content={description}
        />
      </Helmet>

      <Header />
      {children}
      <Footer {...footerProps} />
    </BasicPage>
  );
};

export { Page };
