import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Grid, units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { CustomTitle } from '../CustomTitle';

import { Accordions } from './Accordions';
import { QuestionsTypes } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: units.pxToRem(60, 16),
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(80, 0),
    },
  },
  title: {
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    fontSize: units.pxToRem(28),
    lineHeight: 1.21,
    color: theme.palette.grey[800],
    margin: units.pxToRem(0, 0, 32),

    [theme.breakpoints.up('sm')]: {
      fontSize: units.pxToRem(36),
      lineHeight: 1.22,
    },
  },
}));

interface Props {
  questions: QuestionsTypes[];
  customClasses?: string;
}

const FAQ: React.FC<Props> = ({ questions, customClasses }) => {
  const { classes, cx } = useStyles();
  const { T } = useIntl();

  return (
    <section
      className={cx(classes.container, customClasses)}
      data-test-locator="HowWeHire/FAQ"
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item>
          <CustomTitle title={T('careers.hiring.sub_head_faq')} />
        </Grid>
        <Grid item>
          <h2 className={classes.title}>{T('careers.faq.title')}</h2>
        </Grid>
        <Grid item>
          <Accordions questions={questions} />
        </Grid>
      </Grid>
    </section>
  );
};

export { FAQ };
