import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { IconButton, units } from '@hbf/dsl/legacy';

const useStyles = makeStyles<void, 'opened'>()((_theme, _params, classes) => ({
  opened: {},
  icon: {
    width: units.rem(1.4375),
    height: units.rem(1.1875),
    position: 'relative',
    transform: 'rotate(0deg)',
    transition: '0.5s ease-in-out',
    cursor: 'pointer',

    '& span': {
      display: 'block',
      position: 'absolute',
      left: 0,
      height: units.rem(0.1875),
      width: '100%',
      borderRadius: units.rem(0.1875),
      opacity: 1,
      transform: 'rotate(0deg)',
      transition: '0.25s ease-in-out',
      backgroundColor: _theme.palette.secondary.main,
    },

    '& #line-1': {
      top: 0,
    },

    '& #line-2,#line-3': {
      top: units.rem(0.5),
    },

    '& #line-4': {
      top: units.rem(1),
    },

    [`&.${classes.opened} #line-1`]: {
      top: units.rem(0.5),
      width: 0,
      left: '50%',
    },

    [`&.${classes.opened} #line-2`]: {
      transform: 'rotate(45deg)',
    },

    [`&.${classes.opened} #line-3`]: {
      transform: 'rotate(-45deg)',
    },

    [`&.${classes.opened} #line-4`]: {
      top: units.rem(0.5),
      left: '50%',
      width: 0,
    },
  },
}));

interface Props {
  isOpen: boolean;
  handleClick: () => void;
}

const MenuButton: React.FC<Props> = ({ handleClick, isOpen }) => {
  const { classes, cx } = useStyles();
  return (
    <IconButton
      size="small"
      onClick={handleClick}
      data-test-locator="Careers/Header/MenuIcon"
    >
      <div className={cx(classes.icon, { [classes.opened]: isOpen })}>
        <span id="line-1" />
        <span id="line-2" />
        <span id="line-3" />
        <span id="line-4" />
      </div>
    </IconButton>
  );
};

export { MenuButton };
