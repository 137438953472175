import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { CustomContainer } from '../CustomContainer';
import { CustomTitle } from '../CustomTitle';

import { Team } from './Team';
import { TeamTypes } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    '& > div > p': {
      textAlign: 'center',
    },
  },
  outlinedContainer: {
    padding: units.pxToRem(30, 0),
    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(40, 0),
    },
  },
  containedContainer: {
    backgroundColor: theme.palette.grey[50],
    padding: units.pxToRem(40, 0),
    margin: units.pxToRem(30, 0),
    [theme.breakpoints.up('md')]: {
      margin: units.pxToRem(40, 0),
    },
  },
  subTitle: {
    fontSize: units.pxToRem(26),
    lineHeight: 1.22,
    color: theme.palette.grey[800],
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    margin: '0 auto',
    maxWidth: units.pxToRem(500),
    [theme.breakpoints.up('sm')]: {
      fontSize: units.pxToRem(36),
    },
  },
  description: {
    fontSize: units.pxToRem(18),
    lineHeight: 1.33,
    color: theme.palette.grey[800],
    margin: `${theme.typography.pxToRem(20)} auto 0`,
    maxWidth: units.pxToRem(530),
    [theme.breakpoints.up('sm')]: {
      fontSize: units.pxToRem(20),
      lineHeight: 1.5,
    },
  },
  teams: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: units.pxToRem(16),
    marginTop: units.pxToRem(32),
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: units.pxToRem(24, 16),
    },
    [theme.breakpoints.up('md')]: {
      gap: units.pxToRem(24),
      marginTop: units.pxToRem(28),
    },
  },
  teamsThreeCol: {
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
}));

export interface Props {
  title: string;
  subTitle: string;
  description: string;
  teams: TeamTypes[];
  variant: 'outlined' | 'contained';
  columnsNumber: 2 | 3;
  customClasses?: string;
}

const Teams: React.FC<Props> = ({
  title,
  subTitle,
  description,
  teams,
  variant,
  columnsNumber,
  customClasses,
}) => {
  const { classes, cx } = useStyles();

  return (
    <section
      className={cx(
        classes.container,
        customClasses,
        classes[`${variant}Container`],
      )}
      data-test-locator="Careers/Teams"
    >
      <CustomContainer>
        <CustomTitle title={title} />
        <p className={classes.subTitle}>{subTitle}</p>
        <p className={classes.description}>{description}</p>

        <div
          className={cx(classes.teams, {
            [classes.teamsThreeCol]: columnsNumber === 3,
          })}
          data-test-locator="Careers/Teams/Grid"
        >
          {teams.map(team => (
            <Team key={team.title} team={team} />
          ))}
        </div>
      </CustomContainer>
    </section>
  );
};

export { Teams };
