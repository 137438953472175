import React, { IframeHTMLAttributes } from 'react';

interface Props extends IframeHTMLAttributes<HTMLIFrameElement> {
  allowFullScreen?: boolean;
  height?: number | string;
  width?: number | string;
  embedUrl: string;
}

const Frame: React.FC<Props> = ({
  allowFullScreen = true,
  height = '100%',
  width = '100%',
  embedUrl,
  loading,
  title,
}) => (
  <iframe
    title={title}
    src={embedUrl}
    width={width}
    height={height}
    allowFullScreen={allowFullScreen}
    scrolling="no"
    frameBorder="0"
    loading={loading}
  />
);

export { Frame };
