import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { Image, Link } from 'ha/pages/Careers/components';
import { Domain } from 'ha/pages/Careers/constants';

import { CardTypes } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: units.rem(1),
    boxShadow: '0px 2px 16px rgba(60, 64, 67, 0.16)',
    overflow: 'hidden',
  },
  img: {
    objectPosition: 'top',
    height: units.rem(10.625),
    [theme.breakpoints.up('md')]: {
      height: units.rem(14),
    },
  },
  content: {
    padding: theme.spacing(2.5),
  },
  title: {
    color: theme.palette.grey[800],
    fontSize: units.rem(1.25),
    lineHeight: units.rem(1.875),
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    margin: 0,
  },
  description: {
    color: theme.palette.grey[800],
    lineHeight: units.rem(1.375),
    margin: theme.spacing(1.5, 0, 2),
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
}));

interface Props {
  card: CardTypes;
}

const Card: React.FC<Props> = ({ card }) => {
  const { classes } = useStyles();
  const { T, urlResolver } = useIntl();

  return (
    <div className={classes.container} data-test-locator="Careers/Culture/Card">
      <Image
        alt={T(card.title)}
        name={card.imageName}
        domain={Domain.HomePage}
        className={classes.img}
        loading="lazy"
      />
      <div className={classes.content}>
        <p className={classes.title}>{T(card.title)}</p>
        <p className={classes.description}>{T(card.description)}</p>
        <Link
          url={urlResolver[card.urlBuilder]()}
          linkText={T(card.linkText)}
        />
      </div>
    </div>
  );
};

export { Card };
