import { defineKey } from '@ha/intl';

import { CardTypes } from './types';

export const CARDS: CardTypes[] = [
  {
    title: defineKey('careers.life_at_ha.title'),
    description: defineKey('careers.life_at_ha.intro'),
    imageName: 'life-at-ha3',
    linkText: defineKey('careers.cta.learn_culture'),
    urlBuilder: 'getCultureUrl',
  },
  {
    title: defineKey('careers.hiring.title'),
    description: defineKey('careers.hiring.intro'),
    imageName: 'know-you2',
    linkText: defineKey('careers.cta.how_we_hire'),
    urlBuilder: 'getHowWeHireUrl',
  },
];
