import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { BlogTypes } from 'ha/pages/Careers/components/Blogs/types';

import { CustomTitle } from '../CustomTitle';

import { Blog } from './Blog';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: units.pxToRem(20),
    padding: units.pxToRem(30, 0, 60),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      columnGap: units.pxToRem(24),
      padding: units.pxToRem(30, 0, 80),
    },
    [theme.breakpoints.up('md')]: {
      columnGap: units.pxToRem(120),
      padding: units.pxToRem(40, 32, 100),
    },
  },
  content: {
    width: units.pxToRem(240),
    [theme.breakpoints.up('sm')]: {
      width: units.pxToRem(184),
    },
  },
  subTitle: {
    color: theme.palette.grey[800],
    fontSize: units.pxToRem(28),
    lineHeight: 1.22,
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: units.pxToRem(36),
    },
  },
  blogs: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: units.pxToRem(16),
  },
  placeholder: {
    width: '100%',
    minHeight: units.pxToRem(30),
    [theme.breakpoints.up('md')]: {
      minHeight: units.pxToRem(40),
    },
  },
}));

interface Props {
  blogs?: BlogTypes[];
}

const Blogs: React.FC<Props> = ({ blogs = [] }) => {
  const { classes } = useStyles();
  const { T } = useIntl();

  if (blogs.length === 0) {
    return <div className={classes.placeholder} />;
  }

  return (
    <section className={classes.container}>
      <div className={classes.content}>
        <CustomTitle title={T('careers.sub_head.blog')} />
        <p className={classes.subTitle}>
          {T('careers.main_team_update.title')}
        </p>
      </div>

      <div className={classes.blogs}>
        {blogs.map(blog => (
          <Blog key={blog.title} blog={blog} />
        ))}
      </div>
    </section>
  );
};

export { Blogs };
