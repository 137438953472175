import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { Domain } from 'ha/pages/Careers/constants';

import { Image } from '../Image';

import { CardTypes } from './types';

const useStyles = makeStyles()(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: units.pxToRem(16),
    overflow: 'hidden',
  },
  img: {
    flexShrink: 0,
    objectPosition: 'right',
    height: units.pxToRem(158),
    [theme.breakpoints.up('md')]: {
      height: units.pxToRem(223),
    },
  },
  content: {
    height: '100%',
    padding: units.pxToRem(20),
    border: units.border(1, 'solid', theme.palette.grey[100]),
    borderRadius: units.pxToRem(0, 0, 16, 16),
    borderTop: 0,
  },
  title: {
    color: theme.palette.grey[800],
    fontSize: units.pxToRem(20),
    lineHeight: 1.2,
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    margin: 0,
  },
  description: {
    color: theme.palette.grey[800],
    lineHeight: 1.38,
    margin: units.pxToRem(12, 0, 0),
  },
}));

interface Props {
  card: CardTypes;
  domain: Domain;
}

const Card: React.FC<Props> = ({ card, domain }) => {
  const { classes } = useStyles();
  const { T } = useIntl();

  return (
    <div
      className={classes.container}
      data-test-locator="Careers/Engineering/Card"
    >
      <Image
        alt={T(card.title)}
        name={card.image}
        domain={domain}
        className={classes.img}
        loading="lazy"
      />
      <div className={classes.content}>
        <p className={classes.title}>{T(card.title)}</p>
        <p className={classes.description}>{T(card.description)}</p>
      </div>
    </div>
  );
};

export { Card };
