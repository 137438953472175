import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { IconButton } from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';

import { socialLinks } from '../../constants';

export const NOFOLLOW_REF = 'nofollow noopener noreferrer';

const useStyles = makeStyles()(theme => ({
  item: {
    padding: 0,
    backgroundColor: theme.palette.primary.light,
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.white.main,

    [theme.breakpoints.up('md')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    '&:not(:last-of-type)': {
      marginRight: theme.spacing(0.5),
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow:
        '0px 1px 4px rgba(60, 64, 67, 0.26), 0px 1px 4px 1px rgba(60, 64, 67, 0.16)',
    },
    '&:active': {
      color: theme.palette.primary.dark,
    },
  },
}));

const SocialLinks: React.FC = () => {
  const { classes } = useStyles();
  const { urlResolver } = useIntl();

  return (
    <div data-test-locator="Careers/SocialLinks">
      {socialLinks.map(({ label, icon, urlBuilder }) => (
        <IconButton
          className={classes.item}
          key={label}
          href={urlResolver[urlBuilder]()}
          rel={NOFOLLOW_REF}
          target="_blank"
          size="small"
          data-test-locator="Careers/SocialLinks/Link"
        >
          {icon}
        </IconButton>
      ))}
    </div>
  );
};

export { SocialLinks };
