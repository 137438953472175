import loadable from '@loadable/component';

export const CareersRoutes = {
  careersInformation: {
    culture: loadable(() => import('./pages/Culture')),
    'how-we-hire': loadable(() => import('./pages/HowWeHire')),
  },
  careersDepartments: {
    'data-analytics': loadable(() => import('./pages/DataAnalytics')),
    engineering: loadable(() => import('./pages/Engineering')),
    finance: loadable(() => import('./pages/Finance')),
    internships: loadable(() => import('./pages/Internships')),
    marketing: loadable(() => import('./pages/Marketing')),
    'people-ops': loadable(() => import('./pages/PeopleOps')),
    'product-design': loadable(() => import('./pages/ProductDesign')),
    sales: loadable(() => import('./pages/Sales')),
    support: loadable(() => import('./pages/Support')),
  },
};

export const AllRoutes = {
  ...CareersRoutes.careersDepartments,
  ...CareersRoutes.careersInformation,
};
