import React from 'react';

import { makeStyles } from 'tss-react/mui';

import {
  SwipeableDrawer,
  units,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Divider,
} from '@hbf/dsl/legacy';
import { ArrowButtonDown } from '@hbf/icons/streamline-bold';

import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { useIntl } from 'ha/i18n';

import { CustomButton } from '../CustomButton';

import { Link as LinkInt } from './constants';
import { DropdownMenuItem } from './DropdownMenuItem';
import { HomepageLink } from './HomepageLink';

const useStyles = makeStyles()(theme => ({
  drawerRoot: {
    top: `${units.rem(3.5)} !important`,
  },
  drawerPaper: {
    marginTop: `calc(${units.rem(3.5)} + 1px)`,
    background: 'transparent',
    boxShadow: '0px 2px 16px rgba(60, 64, 67, 0.16)',
  },
  backdropProps: {
    background: 'transparent',
  },
  container: {
    height: `calc(${units.vh(100)} - ${units.rem(3.5)})`,
    width: theme.spacing(31.5),
    padding: theme.spacing(1.125, 2.5, 2.5, 2.5),
    backgroundColor: theme.palette.common.white,
  },
  menuItem: {
    padding: theme.spacing(1, 0, 1),
    fontWeight: 600,
    fontSize: units.rem(0.875),
    lineHeight: 1.29,
    color: theme.palette.grey[600],
  },
  menuItemAccordionRoot: {
    color: 'inherit',
    paddingBottom: theme.spacing(0.75),

    '&:hover': {
      color: theme.palette.grey[800],
    },

    '&:hover svg': {
      color: theme.palette.grey[800],
    },
  },
  menuItemAccordionSummary: {
    justifyContent: 'flex-start',
    padding: 0,
    minHeight: 'auto',
  },
  menuItemAccordionSummaryContent: {
    margin: 0,
    flexGrow: 0,
    paddingRight: theme.spacing(1),
  },
  menuItemAccordionSummaryContentExpanded: {
    margin: '0 !important',
    minHeight: 'auto !important',
  },
  menuItemAccordionSummaryExpandIcon: {
    padding: 0,
    '& svg': {
      width: theme.spacing(1.25),
      color: theme.palette.grey[600],
    },
  },
  menuItemAccordionDetails: {
    padding: theme.spacing(1.5, 0, 0, 2.5),
    flexDirection: 'column',
    color: theme.palette.grey[500],
  },
  menuItemAccordionDetailslinks: {
    padding: theme.spacing(1, 0),
  },
  menuItemButton: {
    textAlign: 'center',
    margin: theme.spacing(1.5, 0),
  },
  haLink: {
    fontWeight: 600,
    fontSize: units.rem(0.875),
    lineHeight: 1.29,
    textAlign: 'right',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2.5),
    textTransform: 'lowercase',

    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

export interface MenuLink {
  title: string;
  content?: LinkInt[];
  url?: string;
  noFollow?: boolean;
}

export interface Props {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  data: MenuLink[];
}

const MenuMobile: React.FC<Props> = ({
  isOpen = false,
  onOpen,
  onClose,
  data,
}) => {
  const { T, urlResolver } = useIntl();
  const { classes } = useStyles();
  const { sm } = useMediaQuery();
  const [expanded, setExpanded] = React.useState<boolean | string>(false);

  const handleChange = React.useCallback(
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    },
    [],
  );

  return (
    <SwipeableDrawer
      hideBackdrop
      disableBackdropTransition
      elevation={0}
      anchor="right"
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper, root: classes.drawerRoot }}
      ModalProps={{
        BackdropProps: {
          classes: {
            root: classes.backdropProps,
          },
        },
      }}
      data-test-locator="Careers/Header/Mobile/Menu"
    >
      <Grid container direction="column" className={classes.container}>
        {!sm && (
          <React.Fragment>
            <Grid item className={classes.menuItemButton}>
              <CustomButton
                variant="contained"
                href={urlResolver.getGreenHouseCareersUrl()}
                rel="no-follow"
                data-test-locator="Careers/Header/Mobile/GreenHouse"
              >
                {T('cta.view_open_roles')}
              </CustomButton>
            </Grid>
            <Divider className={classes.divider} />
          </React.Fragment>
        )}

        {data.map(({ title, url, noFollow, content }) => {
          if (url && typeof noFollow === 'boolean') {
            return (
              <Grid item className={classes.menuItem} key={title}>
                <DropdownMenuItem title={title} url={url} noFollow={noFollow} />
              </Grid>
            );
          }

          return (
            <Grid item className={classes.menuItem} key={title}>
              <Accordion
                square
                elevation={0}
                expanded={expanded === title}
                onChange={handleChange(title)}
                className={classes.menuItemAccordionRoot}
                data-test-locator={`Careers/Header/Mobile/Menu/${title}`}
              >
                <AccordionSummary
                  id={`panel-header-${title}`}
                  aria-controls={`panel-content-${title}`}
                  expandIcon={<ArrowButtonDown />}
                  className={classes.menuItemAccordionSummary}
                  classes={{
                    content: classes.menuItemAccordionSummaryContent,
                    expanded: classes.menuItemAccordionSummaryContentExpanded,
                    expandIconWrapper:
                      classes.menuItemAccordionSummaryExpandIcon,
                  }}
                >
                  {title}
                </AccordionSummary>
                <AccordionDetails
                  classes={{ root: classes.menuItemAccordionDetails }}
                >
                  {content &&
                    content.map(link => (
                      <div
                        key={link.title}
                        className={classes.menuItemAccordionDetailslinks}
                      >
                        <DropdownMenuItem {...link} />
                      </div>
                    ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}

        <Grid item className={classes.menuItem}>
          <HomepageLink />
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
};

export { MenuMobile };
