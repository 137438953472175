import React from 'react';

import { makeStyles } from 'tss-react/mui';

import {
  Accordion as DefaultAccordion,
  AccordionDetails,
  AccordionSummary,
  units,
} from '@hbf/dsl/legacy';
import { ArrowButtonDown } from '@hbf/icons/streamline-bold';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { RenderDangerously } from 'ha/helpers/RenderDangerously';

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    textAlign: 'left',
    boxShadow: '0px 2px 6px rgba(60, 64, 67, 0.16)',

    '&.MuiAccordion-rounded': {
      borderRadius: units.pxToRem(8),
    },

    '&.Mui-expanded	': {
      boxShadow: '0px 2px 16px rgba(60, 64, 67, 0.16)',
    },
  },
  summaryRoot: {
    padding: units.pxToRem(0, 28, 0, 20),

    '&.Mui-expanded	': {
      minHeight: 'auto',
    },
  },
  summary: {
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    fontSize: units.pxToRem(18),
    lineHeight: 1.33,
    color: theme.palette.grey[800],
    margin: units.pxToRem(20, 0),

    '&.Mui-expanded	': {
      margin: units.pxToRem(20, 0, 12),
    },
  },
  expandIcon: {
    fontSize: units.pxToRem(10),
    color: theme.palette.primary.main,
  },
  details: {
    fontWeight: 400,
    fontSize: units.pxToRem(16),
    lineHeight: 1.38,
    color: theme.palette.grey[600],
    padding: units.pxToRem(0, 20, 20),
    overflow: 'hidden',

    '& a': {
      color: theme.palette.primary.main,
    },

    '& a:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

interface Props {
  isOpen: boolean;
  onClick: () => void;
  summary: string;
  details: string;
}

const Accordion: React.FC<Props> = ({
  isOpen = false,
  onClick,
  summary,
  details,
}) => {
  const { classes } = useStyles();

  return (
    <DefaultAccordion
      expanded={isOpen}
      onChange={onClick}
      classes={{ root: classes.root }}
    >
      <AccordionSummary
        expandIcon={<ArrowButtonDown className={classes.expandIcon} />}
        classes={{
          root: classes.summaryRoot,
          content: classes.summary,
        }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <RenderDangerously renderWith="span">{details}</RenderDangerously>
      </AccordionDetails>
    </DefaultAccordion>
  );
};

export { Accordion };
