import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { Domain } from 'ha/pages/Careers/constants';

import { Image } from './Image';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: units.pxToRem(30, 0),
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(40, 0),
    },
  },
  img: {
    height: units.pxToRem(247),
    objectPosition: 'center',
    borderRadius: units.pxToRem(16, 16, 0, 0),
    [theme.breakpoints.up('sm')]: {
      flex: 1,
      height: 'unset',
      width: units.pxToRem(160),
      maxWidth: units.pxToRem(318),
      borderRadius: units.pxToRem(16, 0, 0, 16),
    },
    [theme.breakpoints.up('md')]: {
      flex: 2,
      height: units.pxToRem(247),
      maxWidth: units.pxToRem(500),
    },
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: theme.palette.grey[600],
    border: units.border(1, 'solid', theme.palette.grey[100]),
    borderTopWidth: 0,
    borderRadius: units.pxToRem(0, 0, 16, 16),
    padding: units.pxToRem(16),
    [theme.breakpoints.up('sm')]: {
      borderLeft: 0,
      borderTopWidth: units.pxToRem(1),
      borderRadius: units.pxToRem(0, 16, 16, 0),
      paddingRight: units.pxToRem(32),
    },
    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(0, 68, 0, 36),
    },
  },
  withoutImage: {
    borderTopWidth: units.pxToRem(1),
    borderRadius: units.pxToRem(16),
    padding: units.pxToRem(32),
    [theme.breakpoints.up('sm')]: {
      borderLeft: units.border(1, 'solid', theme.palette.grey[100]),
    },
  },
  quote: {
    fontSize: units.pxToRem(20),
    color: theme.palette.grey[800],
    margin: units.pxToRem(0, 0, 16),
  },
  name: {
    fontSize: units.pxToRem(20),
    margin: units.pxToRem(0, 0, 4),
  },
  jobTitle: {
    fontSize: units.pxToRem(16),
    margin: 0,
  },
}));

export interface Props {
  image?: string;
  quote: string;
  name: string;
  jobTitle: string;
  domain: Domain;
}

const Quote: React.FC<Props> = ({ image, quote, name, jobTitle, domain }) => {
  const { classes, cx } = useStyles();

  return (
    <section className={classes.container} data-test-locator="Careers/Quote">
      {image && (
        <Image
          alt={name}
          name={image}
          domain={domain}
          className={classes.img}
        />
      )}
      <div className={cx(classes.content, { [classes.withoutImage]: !image })}>
        <p className={classes.quote}>{quote}</p>
        <p className={classes.name}>{name}</p>
        <p className={classes.jobTitle}>{jobTitle}</p>
      </div>
    </section>
  );
};

export { Quote };
