import React from 'react';

import { AllRoutes } from 'ha/pages/Careers/routes';

const useValidateSubpage = (subPath: string | undefined) => {
  return React.useMemo(() => {
    if (!subPath) {
      return null;
    }

    const trimmedPath = subPath.toLowerCase() as keyof typeof AllRoutes; // to avoid typescript errors in AllRoutes

    const Component = AllRoutes[trimmedPath];

    if (!Component) {
      return null;
    }

    return Component;
  }, [subPath]);
};

export { useValidateSubpage };
