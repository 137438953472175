import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Card } from './Card';
import { CARDS } from './constants';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.75),
    padding: theme.spacing(3.75, 0),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      padding: theme.spacing(5, 0, 3.75),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 10),
    },
  },
}));

const Culture: React.FC = () => {
  const { classes } = useStyles();

  return (
    <section className={classes.container}>
      {CARDS.map(card => (
        <Card key={card.title} card={card} />
      ))}
    </section>
  );
};

export { Culture };
