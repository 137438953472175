import React from 'react';

import { useParams } from 'react-router-dom';

import { LoadableNotFoundPage } from 'ha/pages/NotFound';

import { useValidateSubpage } from '../hooks/useValidateSubpage';
import HomePage from '../pages/HomePage';

const RootContainer: React.FC = () => {
  const { subPath } = useParams();
  const Subpage = useValidateSubpage(subPath);

  if (!subPath) {
    return <HomePage />;
  }

  if (!Subpage) {
    return <LoadableNotFoundPage />;
  }

  return <Subpage />;
};

export { RootContainer };
