import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { ButtonCustomProps, Button, units } from '@hbf/dsl/legacy';

const useStyles = makeStyles()(theme => ({
  container: {
    fontSize: units.rem(1),
    lineHeight: 1.25,
    textTransform: 'capitalize',
    padding: theme.spacing(1, 4),
    borderRadius: theme.spacing(1),
  },
  contained: {
    fontWeight: 700,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,

    '&:disabled': {
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[200],
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow:
        '0px 1px 4px rgba(60, 64, 67, 0.26), 0px 1px 4px 1px rgba(60, 64, 67, 0.16)',
    },

    '&:active': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: 'none',
    },
  },
  outlined: {
    fontWeight: 600,
    color: theme.palette.grey[800],
    border: `1px solid ${theme.palette.primary.main}`,
    background: 'transparent',
    '&:disabled': {
      color: theme.palette.grey[400],
      border: `1px solid ${theme.palette.grey[100]}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
}));

const CustomButton: React.FC<ButtonCustomProps> = ({
  children,
  variant = 'outlined',
  className,
  ...props
}) => {
  const { classes, cx } = useStyles();
  return (
    <Button
      disableRipple
      className={cx(classes.container, variant && classes[variant], className)}
      {...props}
    >
      {children}
    </Button>
  );
};

export { CustomButton };
