import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { Image } from 'ha/pages/Careers/components/Image';
import { Domain } from 'ha/pages/Careers/constants';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3.75, 0),
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 0),
    },
  },
  containedContainer: {
    backgroundColor: theme.palette.grey[50],
    margin: theme.spacing(3.75, 0),
    padding: theme.spacing(2.5),
    borderRadius: units.rem(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
      padding: theme.spacing(4),
    },
  },
  content: {
    flex: 1,
    color: theme.palette.grey[600],
    '& p': {
      margin: 0,
    },
    '& > p:first-of-type': {
      color: theme.palette.grey[800],
      marginBottom: theme.spacing(2),
    },
  },
  outlinedContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',

    padding: theme.spacing(2.5),
    border: units.border(1, 'solid', theme.palette.grey[100]),
    borderTopWidth: 'none',
    borderRadius: units.trbl(0, 0, 1, 1),
    '& p:not(:last-of-type)': {
      fontSize: units.rem(1.25),
    },
    [theme.breakpoints.up('sm')]: {
      padding: units.pxToRem(16),
      borderLeft: 'none',
      borderTopWidth: units.rem(0.0625),
      borderRadius: units.trbl(0, 1, 1, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(0, 60, 0, 28),
    },
  },
  containedContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',

    padding: theme.spacing(2.5, 0, 0),
    [theme.breakpoints.up('sm')]: {
      padding: units.pxToRem(14, 16, 14, 0),

      '& p:not(:last-of-type)': {
        fontSize: units.rem(1.125),
      },
    },
    [theme.breakpoints.up('md')]: {
      padding: units.pxToRem(0, 32, 0, 0),
    },
  },
  outlinedImg: {
    objectPosition: 'center',
    height: units.rem(13.75),
    borderRadius: units.trbl(1, 1, 0, 0),
    [theme.breakpoints.up('sm')]: {
      flex: 1,
      width: units.rem(10),
      maxWidth: units.rem(20.375),
      height: 'unset',
      borderRadius: units.trbl(1, 0, 0, 1),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: units.rem(31.25),
      height: units.rem(20),
    },
  },
  containedImg: {
    height: units.rem(8.75),
    borderRadius: units.rem(0.5),
    objectPosition: 'center',
    [theme.breakpoints.up('sm')]: {
      width: units.rem(19.375),
      height: 'unset',
    },
    [theme.breakpoints.up('md')]: {
      width: units.rem(29.75),
      height: units.rem(17.25),
    },
  },
}));

export interface Props {
  imageName: string;
  domain: Domain;
  quote: string;
  name: string;
  jobTitle: string;
  variant: 'outlined' | 'contained';
}

const Quote: React.FC<Props> = ({
  imageName,
  domain,
  quote,
  name,
  jobTitle,
  variant,
}) => {
  const { classes, cx } = useStyles();

  const renderContent = (extraClasses: string) => (
    <div className={cx(classes.content, extraClasses)}>
      <p>{quote}</p>
      <p>{name}</p>
      <p>{jobTitle}</p>
    </div>
  );

  if (variant === 'outlined') {
    return (
      <section className={classes.container} data-test-locator="Careers/Quote">
        <Image
          alt={name}
          name={imageName}
          domain={domain}
          className={classes.outlinedImg}
          loading="lazy"
        />
        {renderContent(classes.outlinedContent)}
      </section>
    );
  }

  return (
    <section
      className={cx(classes.container, classes.containedContainer)}
      data-test-locator="Careers/Quote"
    >
      {renderContent(classes.containedContent)}
      <Image
        alt={name}
        name={imageName}
        domain={domain}
        className={classes.containedImg}
        loading="lazy"
      />
    </section>
  );
};

export { Quote };
