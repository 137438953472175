import React from 'react';

import useScrollTrigger from '@mui/material/useScrollTrigger';

import { makeStyles } from 'tss-react/mui';

import { AppBar, Grid, Toolbar, units, Button } from '@hbf/dsl/legacy';

import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { useIntl } from 'ha/i18n';

import { CustomButton } from '../CustomButton';

import { getTopMobileMenuLinks, getTeamsMenuLinks } from './constants';
import { DropdownMenu } from './DropdownMenu';
import { HeaderLogo } from './HeaderLogo';
import { HomepageLink } from './HomepageLink';
import { MenuButton } from './MenuButton';
import { MenuMobile } from './MenuMobile';

const useStyles = makeStyles()(theme => ({
  appBar: {
    background: theme.palette.common.white,
    color: theme.palette.grey[800],
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  toolbar: {
    minHeight: units.pxToRem(56),

    [theme.breakpoints.up('xs')]: {
      minHeight: units.pxToRem(56),
    },
  },
  desktopMenu: {
    fontSize: units.rem(0.875),
    lineHeight: 1.29,
    color: theme.palette.grey[600],

    '& > button, > a': {
      fontWeight: 600,
      textTransform: 'capitalize',
      padding: units.pxToRem(16, 22, 12, 20),
      borderRadius: 0,
      borderBottom: `${units.pxToRem(4)} solid transparent`,
      transition: 'all 300ms',
    },

    '& > button:hover, > a:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.grey[800],
      borderBottomColor: theme.palette.primary.main,
    },

    '& > a .MuiTouchRipple-ripple': {
      color: theme.palette.primary.light,
    },

    '& > button .MuiTouchRipple-ripple': {
      color: theme.palette.primary.light,
    },
  },
  menuIconButton: {
    color: theme.palette.primary.main,
  },
  allRoles: {
    padding: units.pxToRem(8, 10),

    [theme.breakpoints.up('lg')]: {
      padding: units.pxToRem(8, 32),
    },
  },
}));

const Header: React.FC = () => {
  const intl = useIntl();
  const { T, urlResolver } = intl;
  const { sm, md } = useMediaQuery();
  const { classes } = useStyles();
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = React.useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const handleDrawerClose = React.useCallback(() => {
    setDrawerOpen(false);
  }, []);

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        className={classes.appBar}
        elevation={trigger ? 4 : 0}
        data-test-locator="Careers/Header"
      >
        <Toolbar className={classes.toolbar}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid container item flex={1}>
              <HeaderLogo />
            </Grid>
            {md && (
              <Grid item>
                <Grid
                  item
                  data-test-locator="Careers/Header/Desktop/MiddleMenu"
                >
                  <div className={classes.desktopMenu}>
                    <DropdownMenu
                      id="navbar_desktop_teams"
                      title={T('careers.nav.teams')}
                      data={getTeamsMenuLinks(intl)}
                      testId="Careers/Header/Desktop/MiddleMenu/Teams"
                    />

                    <Button
                      color="inherit"
                      variant="text"
                      href={urlResolver.getCultureUrl()}
                      rel="no-follow"
                      data-test-locator="Careers/Header/Desktop/MiddleMenu/Culture"
                    >
                      {T('careers.nav.culture')}
                    </Button>
                    <Button
                      color="inherit"
                      variant="text"
                      href={urlResolver.getHowWeHireUrl()}
                      rel="no-follow"
                      data-test-locator="Careers/Header/Desktop/MiddleMenu/HowWeHire"
                    >
                      {T('careers.nav.hiring')}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
            {md && (
              <Grid item textAlign="right" flex={1}>
                <HomepageLink />
                <CustomButton
                  variant="contained"
                  href={urlResolver.getGreenHouseCareersUrl()}
                  rel="no-follow"
                  data-test-locator="Careers/Header/AllRoles"
                  className={classes.allRoles}
                >
                  {T('cta.view_open_roles')}
                </CustomButton>
              </Grid>
            )}
            {!md && (
              <Grid item>
                {sm && (
                  <CustomButton
                    variant="contained"
                    href={urlResolver.getGreenHouseCareersUrl()}
                    rel="no-follow"
                    data-test-locator="Careers/Header/GreenHouse"
                    style={{ marginRight: units.rem(1.6) }}
                  >
                    {T('cta.view_open_roles')}
                  </CustomButton>
                )}
                <MenuButton
                  isOpen={isDrawerOpen}
                  handleClick={
                    isDrawerOpen ? handleDrawerClose : handleDrawerOpen
                  }
                />
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      {!md && (
        <MenuMobile
          isOpen={isDrawerOpen}
          onOpen={handleDrawerOpen}
          onClose={handleDrawerClose}
          data={[
            {
              title: T('careers.nav.teams'),
              content: getTeamsMenuLinks(intl),
            },
            ...getTopMobileMenuLinks(intl),
          ]}
        />
      )}
    </React.Fragment>
  );
};

export { Header };
