import { defineKey } from '@ha/intl';
import {
  LocationPin,
  Holidays,
  PersonalDevelopment,
  Commute,
  LanguageCourse,
  PensionPlan,
  ModernOffice,
  Gym,
  PetFriendly,
} from '@hbf/icons/streamline-bold';

import { PerkTypes } from './types';

export const PERKS: PerkTypes[] = [
  {
    title: defineKey('careers.perks.work_anywhere_title'),
    icon: () => LocationPin,
  },
  {
    title: defineKey('careers.perks.holidays_title'),
    icon: () => Holidays,
  },
  {
    title: defineKey('careers.perks.development_budget_title'),
    icon: () => PersonalDevelopment,
  },
  {
    title: defineKey('careers.perks.commute_title'),
    icon: () => Commute,
  },
  {
    title: defineKey('careers.perks.language_course_title'),
    icon: () => LanguageCourse,
  },
  {
    title: defineKey('careers.perks.pension_title'),
    icon: () => PensionPlan,
  },
  {
    title: defineKey('careers.perks.office_title'),
    icon: () => ModernOffice,
  },
  {
    title: defineKey('careers.perks.gym_title'),
    icon: () => Gym,
  },
  {
    title: defineKey('careers.perks.pet_title'),
    icon: () => PetFriendly,
  },
];
