import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Link, theme, units } from '@hbf/dsl/legacy';
import { ArrowDiagonal } from '@hbf/icons/streamline-bold';

import { useIntl } from 'ha/i18n';

import { Img } from 'ha/ui/Img';

import { BlogTypes } from './types';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    columnGap: units.pxToRem(12),
    padding: units.pxToRem(8),
    borderRadius: units.pxToRem(8),
    boxShadow: '0px 2px 6px rgba(60, 64, 67, 0.16)',
    transition: 'box-shadow 0.25s ease-out',
    '&:hover': {
      boxShadow: '0px 2px 16px rgba(60, 64, 67, 0.16)',
    },
  },
  content: {
    flex: 1,
    width: units.pxToRem(172),
    paddingTop: units.pxToRem(2),
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.grey[800],
    fontSize: units.pxToRem(18),
    lineHeight: 1.33,
    fontWeight: 600,
    margin: 0,
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  arrowIcon: {
    fontSize: units.pxToRem(10),
    color: theme.palette.primary.main,
    marginRight: units.pxToRem(8),
  },
  date: {
    color: theme.palette.grey[600],
    fontSize: units.pxToRem(14),
    lineHeight: 1.29,
    margin: units.pxToRem(4, 0),
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: 1.25,
    color: theme.palette.grey[600],
    paddingRight: units.pxToRem(12),
    margin: 0,
  },
  img: {
    width: units.pxToRem(100),
    height: units.pxToRem(96),
    borderRadius: units.pxToRem(8),
  },
}));

interface Props {
  blog: BlogTypes;
}

const Blog: React.FC<Props> = ({ blog }) => {
  const { classes } = useStyles();
  const { T } = useIntl();

  return (
    <Link
      to={blog.url}
      className={classes.container}
      data-test-locator="Careers/Blog"
    >
      <Img alt={T(blog.title)} src={blog.image} className={classes.img} />
      <div className={classes.content}>
        <div className={classes.titleWrapper}>
          <p className={classes.title}>{T(blog.title)}</p>
          <ArrowDiagonal className={classes.arrowIcon} />
        </div>
        <p className={classes.date}>02 Oct 2022</p>
        <p className={classes.description}>{T(blog.description)}</p>
      </div>
    </Link>
  );
};

export { Blog };
