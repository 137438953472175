import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

const useStyles = makeStyles()(theme => ({
  title: {
    fontSize: units.rem(0.875),
    lineHeight: units.rem(1.125),
    color: theme.palette.primary.main,
    fontFamily: sharpSansBold.fontFamily,
    letterSpacing: theme.spacing(0.25),
    fontWeight: 600,
    margin: 0,
    textTransform: 'uppercase',
  },
}));

export interface Props {
  title: string;
}

const CustomTitle: React.FC<Props> = ({ title }) => {
  const { classes } = useStyles();

  return <p className={classes.title}>{title}</p>;
};

export { CustomTitle };
