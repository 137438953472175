import boldSubsetWoff from 'ha/fonts/sharp-sans-bold-subset.woff';
import boldSubsetWoff2 from 'ha/fonts/sharp-sans-bold-subset.woff2';
import boldWoff from 'ha/fonts/sharp-sans-bold.woff';
import boldWoff2 from 'ha/fonts/sharp-sans-bold.woff2';
import boldItalicWoff from 'ha/fonts/sharp-sans-bold-italic.woff';
import boldItalicWoff2 from 'ha/fonts/sharp-sans-bold-italic.woff2';
import semiboldWoff from 'ha/fonts/sharp-sans-semibold.woff';
import semiboldWoff2 from 'ha/fonts/sharp-sans-semibold.woff2';

import { FontInfo } from 'ha/components/LoadFonts';

const fontName = 'Sharp Sans';

export const sourceSansHeaders: FontInfo = {
  fontName,
  fontFamily: `'${fontName}', 'Helvetica', 'Arial', 'sans-serif'`,
  preloadWoff2Files: [boldSubsetWoff2],
  stage1Files: [
    {
      src: `url('${boldSubsetWoff2}') format('woff2'), url('${boldSubsetWoff}') format('woff')`,
      options: { weight: '700', style: 'normal', display: 'swap' },
    },
  ],
  stage2Files: [
    {
      src: `url('${boldWoff2}') format('woff2'), url('${boldWoff}') format('woff')`,
      options: { weight: '700' },
    },
    {
      src: `url('${boldItalicWoff2}') format('woff2'), url('${boldItalicWoff}') format('woff')`,
      options: { weight: '700', style: 'italic' },
    },
  ],
};

export const sharpSansBold: FontInfo = {
  fontName,
  fontFamily: `'${fontName}', 'Helvetica', 'Arial', 'sans-serif'`,
  preloadWoff2Files: [boldSubsetWoff2],
  stage1Files: [
    {
      src: `url('${boldSubsetWoff2}') format('woff2'), url('${boldSubsetWoff}') format('woff')`,
      options: { weight: '700', style: 'normal', display: 'swap' },
    },
  ],
  stage2Files: [],
};

export const sharpSansSemiBold: FontInfo = {
  fontName: `${fontName}-semibold`,
  fontFamily: `'${fontName}-semibold', 'Helvetica', 'Arial', 'sans-serif'`,
  preloadWoff2Files: [semiboldWoff2],
  stage1Files: [
    {
      src: `url('${semiboldWoff2}') format('woff2'), url('${semiboldWoff}') format('woff')`,
      options: { weight: '600', style: 'normal', display: 'swap' },
    },
  ],
  stage2Files: [],
};
