import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Link as DslLink, units } from '@hbf/dsl/legacy';
import { ArrowRight } from '@hbf/icons/streamline-bold';

const useStyles = makeStyles()(theme => ({
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    display: 'inline-flex',
    columnGap: theme.spacing(0.5),
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    textDecoration: 'underline',

    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  withEndIcon: {
    '&:after': {
      content: '"\\00A0"',
      position: 'absolute',
      textDecoration: 'underline',
      bottom: 0,
      letterSpacing: units.rem(20),
      zIndex: -1,
    },
  },
  icon: {
    fontSize: units.rem(0.625),
  },
}));

export interface Props {
  linkText: string;
  url: string;
  hasEndIcon?: boolean;
}

const Link: React.FC<Props> = ({ url, linkText, hasEndIcon = true }) => {
  const { classes, cx } = useStyles();

  return (
    <DslLink
      to={url}
      className={cx(classes.link, hasEndIcon && classes.withEndIcon)}
    >
      {linkText}
      {hasEndIcon && <ArrowRight className={classes.icon} />}
    </DslLink>
  );
};

export { Link };
