import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/legacy';

import { sharpSansBold } from 'ha/constants/fonts/sourceSansHeaders';

import { useIntl } from 'ha/i18n';

import { CustomContainer } from '../CustomContainer';
import { CustomTitle } from '../CustomTitle';

import { WorkTypes } from './types';
import { Work } from './Work';

const useStyles = makeStyles()(theme => ({
  container: {
    backgroundColor: theme.palette.grey[50],
    margin: units.pxToRem(30, 0),
    padding: units.pxToRem(24, 0),
    '& > div > p': {
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      padding: units.pxToRem(40, 0),
    },
    [theme.breakpoints.up('md')]: {
      margin: units.pxToRem(40, 0),
    },
  },
  subTitle: {
    fontSize: units.pxToRem(26),
    lineHeight: 1.22,
    color: theme.palette.grey[800],
    fontFamily: sharpSansBold.fontFamily,
    fontWeight: 600,
    margin: '0 auto',
    maxWidth: units.pxToRem(300),
    [theme.breakpoints.up('sm')]: {
      fontSize: units.pxToRem(36),
      maxWidth: units.pxToRem(365),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'unset',
    },
  },
  teams: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: units.pxToRem(20),
    marginTop: units.pxToRem(20),
    [theme.breakpoints.up('sm')]: {
      gap: units.pxToRem(40, 16),
      marginTop: units.pxToRem(32),
    },
    [theme.breakpoints.up('md')]: {
      gap: units.pxToRem(40, 32),
    },
  },
  teamsTwoCol: {
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  teamsThreeCol: {
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
}));

export interface Props {
  title: string;
  subTitle: string;
  works: WorkTypes[];
  columnsNumber: 2 | 3;
  customClasses?: string;
}

const Works: React.FC<Props> = ({
  title,
  subTitle,
  works,
  columnsNumber,
  customClasses,
}) => {
  const { classes, cx } = useStyles();
  const { T } = useIntl();

  return (
    <section
      className={cx(classes.container, customClasses)}
      data-test-locator="Careers/Works"
    >
      <CustomContainer>
        <CustomTitle title={title} />
        <p className={classes.subTitle}>{subTitle}</p>

        <div
          className={cx(classes.teams, {
            [classes.teamsTwoCol]: columnsNumber === 2,
            [classes.teamsThreeCol]: columnsNumber === 3,
          })}
          data-test-locator="Careers/Works/Grid"
        >
          {works.map(({ title: workTitle, description }) => (
            <Work
              key={workTitle}
              title={T(workTitle)}
              description={T(description)}
            />
          ))}
        </div>
      </CustomContainer>
    </section>
  );
};

export { Works };
