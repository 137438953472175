import { IntlObject } from 'ha/i18n';

export interface Link {
  title: string;
  url: string;
  noFollow: boolean;
}

export const getTeamsMenuLinks = (intl: IntlObject): Link[] => {
  const { T, urlResolver } = intl;

  return [
    {
      title: T('careers.nav.engineering'),
      url: urlResolver.getCareersEngineering(),
      noFollow: false,
    },
    {
      title: T('careers.nav.product_ux'),
      url: urlResolver.getCareersProductDesign(),
      noFollow: false,
    },
    {
      title: T('careers.nav.data_analytics'),
      url: urlResolver.getCareersDataAnalytics(),
      noFollow: false,
    },
    {
      title: T('careers.nav.marketing'),
      url: urlResolver.getCareersMarketing(),
      noFollow: false,
    },
    {
      title: T('careers.nav.sales'),
      url: urlResolver.getCareersSales(),
      noFollow: false,
    },
    {
      title: T('careers.nav.cs'),
      url: urlResolver.getCareersSupport(),
      noFollow: false,
    },
    {
      title: T('careers.nav.finance'),
      url: urlResolver.getCareersFinance(),
      noFollow: false,
    },
    {
      title: T('careers.nav.hr'),
      url: urlResolver.getCareersPeopleOps(),
      noFollow: false,
    },
    {
      title: T('careers.nav.internships'),
      url: urlResolver.getInternshipsUrl(),
      noFollow: false,
    },
  ];
};

export const getTopMobileMenuLinks = (intl: IntlObject): Link[] => {
  const { T, urlResolver } = intl;

  return [
    {
      title: T('careers.nav.culture'),
      url: urlResolver.getCultureUrl(),
      noFollow: false,
    },
    {
      title: T('careers.nav.hiring'),
      url: urlResolver.getHowWeHireUrl(),
      noFollow: false,
    },
  ];
};
